<script>
export default {
  name: 'Popup',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    showCloseBtn: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    closeOnEscape(key) {
      if (key.key === 'Escape' || (key.keyCode !== undefined && key.keyCode === 27)) {
        if (this.value) {
          this.close();
        }
      }
    },

    back() {
      this.$emit('back');
    },
  },
  watch: {
    value(val) {
      if (val) {
        document.addEventListener('keyup', this.closeOnEscape);
      } else {
        document.removeEventListener('keyup', this.closeOnEscape);
      }
    },
  },
};
</script>

<template>
  <transition name="c-popup">
    <div class="c-popup-overlay">
      <div class="c-popup">
        <div class="c-popup__head">
          <div class="c-popup__close" v-if="showCloseBtn" @click="$emit('close')"></div>
        </div>

        <div class="c-popup__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.c-popup-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.c-popup {
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  max-height: 100vh;
  margin: 0 20px;
  transition: all 0.3s ease;
  overflow: auto;
}

.c-popup__head {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  padding-bottom: 0;
}

.c-popup__content {
  position: relative;
  z-index: 3;
}

.c-popup__close {
  position: relative;
  width: 19px;
  height: 19px;
  background-image: url('~src/assets/icons/close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: none;
  cursor: pointer;
  z-index: 4;

  &:focus {
    outline: none;
  }
}

.c-popup-enter {
  opacity: 0;
}

.c-popup-leave-active {
  opacity: 0;
}

.c-popup-enter .c-popup,
.c-popup-leave-active .c-popup {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.c-popup-overlay.c-congratuiation {
  .c-popup__content {
    padding: 0 20px 28px;
  }

  @screen sm {
    .c-popup {
      max-width: 420px;
    }

    .c-popup__content {
      padding: 36px 45px;
    }
  }
}

.c-popup-overlay.c-request-callback {
  .c-popup {
    max-width: 340px;
  }

  .c-popup__content {
    padding: 20px;
  }
}

.c-popup-overlay.c-doctor-appointment {
  .c-popup {
    max-width: 288px;
  }

  .c-popup__content {
    padding: 22px;
  }
}

.c-popup-overlay.c-contact-us {
  .c-popup {
    max-width: 312px;
  }

  .c-popup__content {
    padding: 20px;
  }
}

.c-popup-overlay.c-city-selection {
  .c-popup {
    max-width: 600px;
    width: 100%;
  }

  .c-popup__content {
    padding: 30px 75px 60px;

    @media (max-width: 639px) {
      padding: 28px 20px;
    }
  }
}

.c-popup-overlay.c-notification {
  z-index: 10003;
}

.c-popup-overlay.product-main-block__out-off-production .c-popup {
  background-color: #e9f4ff;
}

.c-popup-overlay.c-notification,
.c-popup-overlay.c-base {
  .c-popup {
    @media screen and (orientation: landscape) and (max-device-width: 900px) {
      max-width: 300px;
      width: 100%;
    }

    @media (max-width: 639px) {
      max-width: 320px;
      width: 100%;
    }

    @media (min-width: 640px) {
      max-width: 600px;
      width: 100%;
    }
  }

  .c-popup__head {
    padding: 12px 12px 0;
    padding-bottom: 0;

    @screen sm {
      padding: 20px;
    }
  }

  .c-popup__close {
    width: 16px;
    height: 16px;

    @screen sm {
      width: 19px;
      height: 19px;
    }
  }

  .c-popup__content {
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;

    @media screen and (orientation: landscape) and (max-device-width: 900px) {
      padding: 32px 20px;
    }

    @media (max-width: 639px) {
      padding: 32px 20px;
    }

    @media (min-width: 640px) {
      padding: 60px 75px 60px 75px;
    }
  }
}

@screen sm {
  .delivery-popup.c-base {
    .c-popup {
      max-width: 420px;
      padding: 0;
    }
    .c-popup__content {
      padding: 0 20px 36px;
    }
  }
}

.product-add-to-cart.c-base {
  .c-popup {
    padding: 40px 40px 40px;
    transition: all 0.3s ease;
    max-width: 800px;
  }
  .c-popup__head {
    padding-right: 20px;
  }
  .c-popup__content {
    padding: 0;
  }
}
</style>
